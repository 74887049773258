import React, {useMemo} from 'react';
import {motion} from 'framer-motion';
import {graphql, Link} from 'gatsby';
import Layout from 'components/Layout';
import {PageHeader} from 'components/Headings';
import {pageVariants, pageTransition} from 'components/Transitions';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import 'wysiwyg.css';


function Blogs({data}) {
  const {
    seomatic,
    title,
    heroImage,
    author,
    categories,
    body,
    relatedblog,
  } = data.craftBlogArticlesBlogArticleEntry;

  const hasUseableImage = useMemo(() => {
    if (!heroImage || heroImage.length === 0) {
      return false;
    }
    if (heroImage[0].width < 1280 || heroImage[0].height < 531) {
      return false;
    }
    return true;
  }, [heroImage]);

  return (
    <Layout hasContact seomatic={seomatic}>
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}>
        <div className="blog font-display">
          <div className="relative flex flex-col">
            <PageHeader heading={title} />
            {hasUseableImage && (
              <div className="max-w-screen-xl m-auto w-full flex items-center justify-center overflow-hidden">
                <GatsbyImage className="hero-image" image={getImage(heroImage?.[0]?.localFile)} alt={title} />
              </div>
            )}
            <div className="md:row pl-3 md:pl-10 blog-author_container gap-8">
              <div className="author">
                <GatsbyImage
                  className="author-image"
                  image={getImage(author?.photo?.localFile)}
                  alt={author.fullName}
                />
                <div className="author-text_container">
                  <div className="author-name">{author.fullName}</div>
                  <div className="author-role">{author.role}</div>
                </div>
              </div>
              <div className="flex flex-wrap row items-center">
                {categories.length > 0 && (
                  <div className="tag-title pl-3 mr-6">Categories:</div>
                )}
                <div className="tags-container">
                  {categories.map(({title}) => (
                    <Link
                      to={`/articles${title === 'All' ? '' : `?${title}`}`}
                      className="tags"
                      key={title}>
                      {title}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            <div
              className="mb-8 flex-1 pl-3 md:pl-10 blog-body wysiwyg"
              dangerouslySetInnerHTML={{__html: body}}
            />
            {relatedblog.length > 0 && (
              <div className="related-articles">
                <p>RELATED ARTICLES</p>
                <div>
                  {relatedblog.map(blog => (
                    <a key={blog.url} href={blog.url}>
                      <GatsbyImage
                        className='related-articles-image'
                        image={getImage(blog.heroImage[0]?.localFile)}
                        alt={title}
                      />
                      <p>{blog.title}</p>
                    </a>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </motion.div>
    </Layout>
  );
}

export const query = graphql`
  fragment CaseStudyImage on Craft_assets_Asset {
    localFile {
      childImageSharp {
        gatsbyImageData(width: 1024, height: 768, quality: 80)
      }
    }
  }
  query ($id: String) {
    craftBlogArticlesBlogArticleEntry(id: {eq: $id}) {
      seomatic {
        ...SeomaticFields
      }
      id
      slug
      uri
      heroImage {
        width
        height
        ... on Craft_assets_Asset {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1280, height: 531, quality: 80, transformOptions: {cropFocus: CENTER})
            }
          }
        }
      }
      title
      author {
        fullName
        photo {
          ... on Craft_assets_Asset {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 80, height: 80, quality: 100)
              }
            }
          }
        }
        ... on Craft_User {
          role
        }
      }
      body
      categories {
        title
      }
      relatedblog {
        title
        url
        ... on Craft_blogArticles_blogArticle_Entry {
          heroImage {
            ... on Craft_assets_Asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 400, height: 250, quality: 80, transformOptions: {cropFocus: CENTER})
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Blogs;
